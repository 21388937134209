import { useState, useCallback, useEffect, memo } from "react";
import { useGetFavoriteInteractionsQuery } from "../../../api/userApi";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../../slices/authSlice";
import MemesGrid from "../grid/MemesGrid";

export const MemesFavoritesList = ({ interactionSettings, searchTerm, activeTab, isDonatePage }) => {

    const isAuthenticated = useSelector(authenticationSelector);
    const [searchParam, setSearchParam] = useState();
    const [memesData, setMemesData] = useState([]);
    const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
    const [hasNext, setHasNext] = useState(false);
    const { data: memes } = useGetFavoriteInteractionsQuery({ term: searchParam, ...pagination }, { skip: activeTab === 0 || !isAuthenticated });

    const loadMoreData = useCallback(() => {
        setPagination(prevValue => ({ ...prevValue, ...{ page: prevValue.page + 1 } }))
    }, [])

    useEffect(() => {
        if (memes?.interactions && memes?.pagination && pagination.page > 0) {
            console.log(memes);
            setMemesData(prevValue => [...prevValue, ...memes.interactions]);
            setHasNext(memes.pagination.hasNext);
        }
        if (memes?.interactions && memes?.pagination && pagination.page === 0) {
            console.log(memes);
            setMemesData(memes.interactions);
            setHasNext(memes.pagination.hasNext);
        }
    }, [memes])

    useEffect(() => {
        if (searchTerm?.length >= 3) {
            const timerId = setTimeout(() => {
                setSearchParam(searchTerm);
                setPagination(prevValue => ({ ...prevValue, ...{ page: 0 } }));
                setMemesData([]);
            }, 1000)

            return () => clearTimeout(timerId)
        } else {
            setSearchParam(undefined);
            setPagination(prevValue => ({ ...prevValue, ...{ page: 0 } }));
        }
    }, [searchTerm])
    
    return (
        <MemesGrid memes={memesData} priceConfig={interactionSettings} isDonatePage={isDonatePage} hasNext={hasNext} onLoad={loadMoreData} />
    )
}

export default memo(MemesFavoritesList);