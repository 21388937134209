import { Box } from "grommet";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectSocket } from "../slices/socketReducer";
import { useOnWindowResize } from "rooks";
import { divide, multiply } from "mathjs";
import VideoPlayer from "../components/webgl/VideoPlayer";

const overlayConfig = {
    width: 660,
    height: 372,
    vidWidth: 246,
    vidHeight: 138.38
}

export const OverlaysPage = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const ws = useSelector(state => state.ws.clients['overlay']);
    const connected = useSelector(state => state.ws.connected['overlay']);
    const [currentInteraction, setCurrentInteraction] = useState();
    const [interactionPosition, setInteractionPosition] = useState({ top: 0, left: 0 });
    const [interactionSize, setInteractionSize] = useState({ height: 0, width: 0 });
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [interactionsQueue, setInteractionsQueue] = useState([]);

    const wsCallback = useCallback((messageOutput) => {
        const body = JSON.parse(messageOutput?.body);
        console.log(body);
        
        // setCurrentInteraction(body);
        setInteractionsQueue(prevValueList => [...prevValueList, body]);
    }, [])

    const handleVideoEnd = useCallback(() => {
        setCurrentInteraction(null);
        setInteractionsQueue(prevValueList => [...prevValueList.slice(1)]);
    }, [])

    const wsTopics = [
        {
            path: `/user/queue/overlay/${params.token}`,
            callback: wsCallback
        }
    ]

    useEffect(() => {
        if (!ws && !connected) {
            dispatch(connectSocket('overlay', `${process.env.REACT_APP_WEBSOCKET_URL}/overlay`, wsTopics, "", params.token))
        }
        if (ws && !connected) {
            console.log("CONNECTING...");
            ws.activate();
        }
        return () => ws && connected ? ws.deactivate() : null;
    }, [ws, connected, wsCallback])

    useOnWindowResize(() => {
        if (currentInteraction?.position) {
            setInteractionPosition({ top: multiply(divide(currentInteraction.position.y, overlayConfig.height), window.innerHeight), left: multiply(divide(currentInteraction.position.x, overlayConfig.width), window.innerWidth) });
            setInteractionSize({ height: multiply(divide(currentInteraction.position.height, overlayConfig.height), window.innerHeight), width: multiply(divide(currentInteraction.position.width, overlayConfig.width), window.innerWidth) });
        }
    }, true, true)

    const showInteraction = useCallback(() => {
        const interaction = interactionsQueue[0];
        setCurrentInteraction(interaction);
        if (interaction.chromaKey) {
            setChromaKeyConfig({
                chromaKey: interaction.chromaKey,
                similarity: interaction.similarity,
                smoothness: interaction.smoothness,
                spill: interaction.spill
            })
        } else {
            setChromaKeyConfig(null);
        }
    }, [interactionsQueue])

    useEffect(() => {
        if (interactionsQueue.length > 0) {
            const timer = setTimeout(showInteraction, 2000);
            return () => clearTimeout(timer);
            // showInteraction();
        }
    }, [interactionsQueue, showInteraction])

    useEffect(() => {
        if (currentInteraction?.position) {
            setInteractionPosition({ top: multiply(divide(currentInteraction.position.y, overlayConfig.height), window.innerHeight), left: multiply(divide(currentInteraction.position.x, overlayConfig.width), window.innerWidth) });
            setInteractionSize({ height: multiply(divide(currentInteraction.position.height, overlayConfig.height), window.innerHeight), width: multiply(divide(currentInteraction.position.width, overlayConfig.width), window.innerWidth) });
        }
    }, [currentInteraction])

    return (
        <Box style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }} >
            {currentInteraction && (currentInteraction.isSpot || (!currentInteraction.isFullscreen && !currentInteraction.isSpot && currentInteraction.position)) 
            && <Box style={{ position: 'absolute', top: `${interactionPosition.top}px`, left: `${interactionPosition.left}px`, height: `${interactionSize.height}px`, width: `${interactionSize.width}px` }}>
                <VideoPlayer 
                    src={currentInteraction.interactionUrl} 
                    removeBgConfig={chromaKeyConfig}
                    autoPlay
                    onEnded={handleVideoEnd}
                />
            </Box>}
            {currentInteraction && (currentInteraction.isFullscreen || (!currentInteraction.isFullscreen && !currentInteraction.isSpot && !currentInteraction.position)) && <Box>
                <VideoPlayer 
                    src={currentInteraction.interactionUrl} 
                    removeBgConfig={chromaKeyConfig}
                    autoPlay
                    onEnded={handleVideoEnd}
                />
            </Box>}
        </Box>
    )
}