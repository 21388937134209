import { Box, Card, CardBody, Image, Stack } from "grommet"
import { memo, useCallback, useContext, useEffect, useState } from "react";
import './MemesGrid.css'
import { useDispatch } from "react-redux";
import { setMemeModal } from "../../../slices/modalWindowsSlice";
import { MemePreviewTitle } from "./MemePreviewTitle";
import { MemeHoverContext } from "./MemesGrid";

const MemePreview = ({ meme, ...props }) => {

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);

    const { handleHoverEvent } = useContext(MemeHoverContext);

    const [memeCardNode, setMemeCardNode] = useState(null);
    const [videoNode, setVideoNode] = useState();

    const videoRef = useCallback((node) => {
        if (!node) return;

        setVideoNode(node);
    }, [])

    const memeCardRef = useCallback((node) => {
        if (!node) return;

        setMemeCardNode(node);
    }, [])

    const handleMouseHover = () => {
        setShow(true);
    }

    const handleMouseLeave = () => {
        setShow(false);
    }

    const handleOpenDialog = () => {
        dispatch(setMemeModal({ show: true, meme: meme }));
    }

    useEffect(() => {
        if (!show) {
            handleHoverEvent(null, null, { top: 0, left: 0 }, { height: 0, width: 0 });
            setHide(false);
        }
    }, [show, videoNode])

    const onVideoCanPlay = () => {
        const memeCardNodeReact = memeCardNode.getBoundingClientRect();
        
        handleHoverEvent(meme, videoNode, { top: memeCardNodeReact.top, left: memeCardNodeReact.left }, { height: memeCardNodeReact.height, width: memeCardNodeReact.width });

        setHide(true);
    }

    return (
        <Box key={meme.id} {...props} onPointerEnter={handleMouseHover} onPointerLeave={handleMouseLeave} onClick={handleOpenDialog} focusIndicator={false}>
            <Card>
                <CardBody margin={'small'} pad={'none'}>
                    <Stack anchor="bottom-left">
                        <Box ref={memeCardRef} style={{ position: 'relative', height: '100%', width: '100%' }} className="meme-preview">
                            <Box>
                                <Image height={'100%'} width={'100%'} fit="contain" src={meme.interactionPreview} style={{ opacity: hide ? 0 : 1 }} />
                            </Box>
                            {show && <Box style={{ position: 'absolute', top: 0, left: 0, opacity: meme.chromaKey ? 0 : 1 }}>
                                <video ref={videoRef} key={meme.id} myId={meme.id} autoPlay loop playsInline muted src={meme.interactionVideo} onCanPlay={onVideoCanPlay} crossOrigin="anonymous" preload="auto"></video>
                            </Box>}
                        </Box>
                        <MemePreviewTitle meme={meme} style={{ position: 'relative', zIndex: 2, pointerEvents: 'none', display: show ? 'flex' : 'none' }}  />
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    )
}

export default memo(MemePreview);