import { forwardRef } from "react";

const Video = forwardRef(({ src, ...props }, ref) => {

    return (
        <video 
            ref={ref}
            playsInline
            src={src}
            crossOrigin="anonymous"
            {...props}
        >
        </video>
    )
})

export default Video;