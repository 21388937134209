import { Box, Button, Card, CardBody, CardHeader, CheckBox, FileInput, Form, FormField, Heading, Image, Keyboard, Layer, Notification, RangeInput, Tag, Text, TextInput } from "grommet"
import { Add, Close, Previous } from "grommet-icons"
import { useEffect, useState } from "react"
import { useCreateInteractionMutation } from "../../../api/interactionsApi";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../slices/notificationsSlice";
import { divide, equal } from "mathjs";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../webgl/VideoPlayer";

const CreateOption = ({ title, description, img, onClick }) => {
    return (
        <Box overflow={'hidden'} width={'medium'} direction="column" border={{ color: '#ffffff5c', size: '1px' }} round hoverIndicator={{ background: { color: 'background-contrast' }, elevation: 'medium' }} onClick={onClick}>
            <Box>
                <Image src={img} />
            </Box>
            <Box direction="column" gap="small" margin={'medium'}>
                <Text weight={'bold'} size="medium">{title}</Text>
                <Text>{description}</Text>
            </Box>
        </Box>
    )
}

const transparentBackgroundArea = 'linear-gradient(45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.15) 75%, rgba(0, 0, 0, 0.15) 0px) 0px 0px / 20px 20px repeat, linear-gradient(45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.15) 75%, rgba(0, 0, 0, 0.15) 0px) 10px 10px / 20px 20px repeat, white';

const titleValidationRules = [
    {
        regexp: /^.{3,255}$/,
        message: 'Title should be minimum 3 length and maximum 255 characters',
        status: 'error',
    }
]

const validateInputVideo = async (file) => {
    const url = URL.createObjectURL(file);

    const videoElement = document.createElement('video');
    videoElement.src = url;

    return await calculateAspectRatio(videoElement);
}

const calculateAspectRatio = videoElement => new Promise((resolve) => {
    videoElement.onloadedmetadata = (event) => {
        const video = event.target;
        resolve(equal(divide(video.videoWidth, video.videoHeight), divide(16, 9)));
    }
});

const memeTypes = {
    FULLSCREEN: 'FULLSCREEN',
    SPOT: 'SPOT',
    MIXED: 'MIXED'
}

export const MemeCreateDialog = ({ show, onClose }) => {

    const { t } = useTranslation('memes');
    const dispatch = useDispatch();
    const [createInteraction] = useCreateInteractionMutation();

    const [filePreview, setFilePreview] = useState();
    const [videoFile, setVideoFile] = useState();
    const [memeTag, setMemeTag] = useState('');
    const [memeTags, setMemeTags] = useState([]);
    const [createForm, setCreateForm] = useState(false);
    const [removeBackground, setRemoveBackground] = useState(false);
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [title, setTitle] = useState();
    const [chromaKey, setChromaKey] = useState('#03fc03');
    const [similarity, setSimilarity] = useState(0.3);
    const [smoothness, setSmoothness] = useState(0.15);
    const [spill, setSpill] = useState(0.01);
    const [showAspectRatioError, setShowAspectRatioError] = useState();
    const [memeType, setMemeType] = useState();

    const handleClose = () => {
        onClose();
        handleBack();
        setRemoveBackground(false);
    }

    const handleTagAdd = (event) => {
        event.preventDefault();
        const hasNotTag = memeTags.indexOf(memeTag.trim()) === -1;
        if (hasNotTag && memeTags.length < 5 && memeTag.length >= 3) {
            setMemeTags([...memeTags, memeTag.trim()]);
        }
        setMemeTag('');
    }

    const handleTagInput = (event) => {
        setMemeTag(event.target.value);
    }

    const handleBack = () => {
        setFilePreview(undefined);
        setCreateForm(false);
        setMemeType(null);
        setMemeTags([]);
        setVideoFile(undefined);
        setTitle(undefined);
    }

    const handleMemeCreateType = (memeType) => {
        setCreateForm(true);
        setMemeType(memeType);
    }

    useEffect(() => {
        if (videoFile) {
            console.log(videoFile);

            if((memeType === memeTypes.FULLSCREEN || memeType === memeTypes.MIXED )) {
                validateInputVideo(videoFile).then(result => {
                    setShowAspectRatioError(!result)
                });
            }
            setFilePreview(URL.createObjectURL(videoFile));
        } else {
            setFilePreview(undefined); 
            setRemoveBackground(false);
            setShowAspectRatioError(false);
        }
    }, [videoFile])

    useEffect(() => {
        if (removeBackground) {
            setChromaKeyConfig({ 
                chromaKey: chromaKey,
                similarity: similarity,
                smoothness: smoothness,
                spill: spill
            })
        } else {
            setChromaKeyConfig(null);
        }
    }, [removeBackground, chromaKey, similarity, smoothness, spill])

    const handleCreateMeme = () => {
        
        const requestBody = {
            interaction: {
                title: title,
                tags: memeTags,
                type: memeType,
                ...chromaKeyConfig
            },
            interactionVideo: videoFile
        }

        createInteraction(requestBody)
            .unwrap()
            .then(() => {
                handleClose();
                dispatch(addNotification({ show: true, message: 'Your meme is taken to moderation. Please, wait for review.', status: 'normal' }));
            })
            .catch((errorResponse) => {
                console.log(errorResponse);
                if (errorResponse?.data?.length > 0) {
                    const errors = errorResponse?.data;
                    errors.forEach((error) => dispatch(addNotification({ show: true, message: error?.message, status: 'critical' })))
                } else {
                    dispatch(addNotification({ show: true, message: errorResponse?.error, status: 'critical' }))
                }
            });
    }

    if (show) {
        return (
            <Layer onClickOutside={handleClose}>
                <Card background="#222222" width={'xlarge'}>
                    <CardHeader direction="column" align="start" pad={{ top: 'medium', left: 'medium', right: 'medium', bottom: 'none' }}>
                        {createForm && <Box direction="row" align="center" fill justify="between"><Button icon={<Previous/>} onClick={handleBack} /><Button icon={<Close/>} onClick={handleClose}/></Box>}
                        {!createForm && <Box direction="row" align="center" fill justify="between"><Heading level={2} weight={'bold'} margin={'none'}>{t('createMemeTitle')}</Heading><Button icon={<Close/>} onClick={handleClose}/></Box>}
                    </CardHeader>
                    <CardBody direction="column" gap="small" fill>
                        {createForm && (
                                <Form validate="blur" messages={{ required: 'Required field' }} onSubmit={handleCreateMeme} >
                                    <Box direction="row" gap="medium" margin={{ bottom: 'small' }}>
                                        <Box fill>
                                            {filePreview && <VideoPlayer 
                                                src={filePreview} 
                                                removeBgConfig={chromaKeyConfig}
                                                autoPlay
                                                loop
                                                controls
                                                className="meme-preview"
                                                background={transparentBackgroundArea}
                                            />}
                                            {!filePreview && (<Image src={`${process.env.PUBLIC_URL}/images/video_preview.png`} />)}
                                        </Box>
                                        <Box fill>
                                            <FormField label={t('memeTitle')} htmlFor="title" name="title" required={{ indicator: true }} validate={titleValidationRules}>
                                                <TextInput id="title" name="title" value={title} onChange={(event) => setTitle(event.target.value)} />
                                            </FormField>
                                            <Notification status="warning" title={<Text size="xsmall">{t('createMemeWarn')}</Text>} />
                                            <FormField label={t('videoTitle')} htmlFor="video" name="video" required={{ indicator: true }} validate={() => showAspectRatioError ? t('aspectRatioError') : undefined}>
                                                <FileInput id="video" name="video" onChange={(event, { files }) => files.length ? setVideoFile(files[0]) : setVideoFile(undefined)} maxSize={30000000}/>
                                            </FormField>
                                            <Box border={{ color: '#ffffff5c', size: '1px' }} round={'small'} pad={'small'} margin={{ top: 'xsmall' }}>
                                                <Box margin={{ right: 'small', bottom: 'small' }}>
                                                    <CheckBox toggle label={t('removeBackgroundTitle')} pad={'none'} checked={removeBackground} onClick={(event) => setRemoveBackground(event.target.checked)}/>
                                                </Box>
                                                <Box direction="column" fill>
                                                    <FormField label={t('backgroundColorTitle')} htmlFor="labelColor" name="labelColor">
                                                        <TextInput id="labelColor" name="labelColor" type="color" style={{ padding: 0 }} value={chromaKey} onChange={(event) => setChromaKey(event.target.value)}/>
                                                    </FormField>
                                                    <FormField htmlFor="similarity" label={t('similarityTitle')} noBorder>
                                                        <RangeInput id="similarity" name="similarity" min={0} max={1} step={0.001} value={similarity} onChange={(event) => setSimilarity(event.target.value)} />
                                                    </FormField>
                                                    <FormField htmlFor="smoothness" label={t('smoothnessTitle')} noBorder>
                                                        <RangeInput id="smoothness" name="smoothness" min={0} max={1} step={0.001} value={smoothness} onChange={(event) => setSmoothness(event.target.value)} />
                                                    </FormField>
                                                    <FormField htmlFor="spill" label={t('spillTitle')} noBorder>
                                                        <RangeInput id="spill" name="spill" min={0} max={1} step={0.001} value={spill} onChange={(event) => setSpill(event.target.value)} />
                                                    </FormField>
                                                </Box>
                                            </Box>
                                            <FormField label={t('memeTagsTitle')} info={t('memeTagsInfo')}>
                                                <Keyboard onEnter={handleTagAdd}>
                                                    <TextInput value={memeTag} onChange={handleTagInput} />
                                                </Keyboard>
                                            </FormField>
                                            <Box direction="row" gap="small" responsive wrap>
                                                {memeTags?.map((tag, index) => (<Box margin={{ bottom: 'small' }}><Tag size="small" value={`#${tag}`} onRemove={() => setMemeTags((prevState) => prevState.filter((value, idx) => idx !== index))} /></Box>))}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Button fill label={t('createMemeButton')} icon={<Add/>} primary type="submit" />
                                </Form>
                            )
                        }
                        {/* {createForm && <Button label="Create" icon={<Add/>} primary onClick={handleCreateMeme} />} */}
                        {!createForm && (<Box direction="row" gap="medium" >
                            <CreateOption onClick={() => handleMemeCreateType(memeTypes.FULLSCREEN)} title={'Fullscreen'} description={t('fullscreenMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/fullscreen_meme.png`} />
                            <CreateOption onClick={() => handleMemeCreateType(memeTypes.SPOT)} title={'Spot'} description={t('spotMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/spot_meme.png`} />
                            <CreateOption onClick={() => handleMemeCreateType(memeTypes.MIXED)} title={'Mixed'} description={t('mixedMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/mixed_meme.png`} />
                        </Box>)}
                    </CardBody>
                </Card>
            </Layer>
        )
    }
}