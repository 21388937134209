export default {
    loadShader: (context, type, source) => {
        const shader = context.createShader(type);
                
        context.shaderSource(shader, source);
        context.compileShader(shader);

        if (!context.getShaderParameter(shader, context.COMPILE_STATUS)) {
            console.error(context.getShaderInfoLog(shader));
        }

        return shader;
    },
    createProgram: (context, vertexShader, fragmentShader) => {
        const program = context.createProgram();

        context.attachShader(program, vertexShader);
        context.attachShader(program, fragmentShader);
        context.linkProgram(program);
        context.useProgram(program);

        return program;
    },
    createTexture: (context) => {
        const texture = context.createTexture();

        context.bindTexture(context.TEXTURE_2D, texture);

        context.texParameteri(context.TEXTURE_2D, context.TEXTURE_WRAP_S, context.CLAMP_TO_EDGE);
        context.texParameteri(context.TEXTURE_2D, context.TEXTURE_WRAP_T, context.CLAMP_TO_EDGE);
        context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MIN_FILTER, context.LINEAR);
        context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MAG_FILTER, context.LINEAR);
    },
    setShaderBuffer: (context, location, vertices) => {
        const buffer = context.createBuffer();
        
        context.bindBuffer(context.ARRAY_BUFFER, buffer);
        context.bufferData(context.ARRAY_BUFFER, vertices, context.STATIC_DRAW);

        context.vertexAttribPointer(location, 2, context.FLOAT, false, 0, 0);
        context.enableVertexAttribArray(location);
    }
}