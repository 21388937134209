import { useCallback, useEffect } from "react";
import { Tabs, Tab, Spinner } from "grommet";
import { BoxWindow } from "../components/BoxWindow";
import { useDispatch, useSelector } from "react-redux";
import { addNewDonate } from "../slices/donatesSlice";
import { Settings } from "../components/auc-settings/Settings";
import { useTranslation } from "react-i18next";
import { WheelAbout } from "../components/wheel-about/WheelAbout";
import { useGetCurrentUserQuery } from "../api/userApi";
import { authenticationSelector } from "../slices/authSlice";
import { connectSocket } from "../slices/socketReducer";
import { AuctionViewTab } from "../components/auction/AuctionViewTab";
import { WheelViewTab } from "../components/wheel/WheelViewTab";

export const AuctionPage = () => {

	const { t, ready } = useTranslation(["lots-table", "wheel"], {
		useSuspense: false,
	});

	const dispatch = useDispatch();
	const isAuthenticated = useSelector(authenticationSelector);
	const { isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(
		undefined,
		{ skip: !isAuthenticated }
	);
	const ws = useSelector((state) => state.ws.clients["donates"]);
	const connected = useSelector((state) => state.ws.connected["donates"]);

	const topicCallback = useCallback((messageOutput) => {
		const body = JSON.parse(messageOutput?.body);
		const donate = {
			id: body.id,
			donate: body.donate,
			sender: body.sender,
			message: body.message,
			amount: body.amount,
		};
		dispatch(addNewDonate(donate));
	}, []);

	const wsTopics = [
		{
			path: `/user/queue/donates`,
			callback: topicCallback,
		},
	];

	useEffect(() => {
		if (isAuthenticated && isCurrentUserSuccess) {
			if (!ws && !connected) {
				dispatch(
					connectSocket(
						"donates",
						`${process.env.REACT_APP_WEBSOCKET_URL}/donates`,
						wsTopics
					)
				);
			}
			if (ws && !connected) {
				console.log("CONNECTING...");
				ws.activate();
			}
		}
		// return () => (ws && connected ? ws.deactivate() : null);
	}, [ws, isAuthenticated, isCurrentUserSuccess]);

	if (!ready) {
		return <Spinner />;
	}

	if (ready) {
		return (
			<>
				<Tabs alignControls="center" flex="grow">
					<Tab title={t("tab1")}>
						<BoxWindow>
							<AuctionViewTab />
						</BoxWindow>
					</Tab>
					<Tab title={t("tab2")}>
						<BoxWindow>
							<WheelViewTab />
						</BoxWindow>
					</Tab>
					<Tab title={t("tab3")}>
						<BoxWindow>
							<Settings />
						</BoxWindow>
					</Tab>
					<Tab title={t("tab4")}>
						<BoxWindow>
							<WheelAbout />
						</BoxWindow>
					</Tab>
					{/* <Tab title={t('tab5')} disabled>
                
              </Tab>
              <Tab title={t('tab6')} disabled>
                
              </Tab> */}
				</Tabs>
			</>
		);
	}
};
