import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react"
import Video from "./Video"
import WebGLCanvas from "./WebGLCanvas"
import { Box } from "grommet";
import { useOnWindowResize } from "rooks";

const WebGLVideo = forwardRef(({ src, removeBgConfig, autoPlay, loop, muted, onTimeUpdate, onCanPlay, onPlay, onPause, ...props }, ref) => {

    const canvasRef = useRef();
    const videoRef = useRef();

    const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 });

    const onVideoPlaying = () => {
        canvasRef.current.play(videoRef.current);
    }

    const onVideoCanPlay = (event) => {
        const video = event.target;

        const videoRect = video.getBoundingClientRect();

        setCanvasSize({ height: videoRect.height, width: videoRect.width });

        onCanPlay(event);
    }

    // useOnWindowResize(() => {
    //     const videoRect = videoRef.current.getBoundingClientRect();
    //     console.log(videoRect);
        
    //     setCanvasSize({ height: videoRect.height, width: videoRect.width });
    // }, true, true)

    useLayoutEffect(() => {
        const observer = new ResizeObserver(() => {
            const rect = videoRef.current.getBoundingClientRect();

            setCanvasSize({ height: rect.height, width: rect.width });
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const play = () => {
        videoRef.current?.play();
    };

    const stop = () => {
        videoRef.current?.pause();
    };

    useImperativeHandle(ref, () => {
        return {
            play,
            stop,
            getVideo: () => videoRef.current
        }
    }, [])

    return (
        <Box style={{ position: 'relative' }} {...props}>
            <WebGLCanvas 
                ref={canvasRef} 
                removeBgConfig={removeBgConfig} 
                height={canvasSize.height} 
                width={canvasSize.width}
                style={{ position: 'absolute', top: 0, left: 0, height: `${canvasSize.height}px`, width: `${canvasSize.width}px` }}
            />
            <Video 
                src={src} 
                onCanPlay={onVideoCanPlay}
                onPlay={onPlay}
                onPlaying={onVideoPlaying}
                onPause={onPause}
                onTimeUpdate={onTimeUpdate}
                style={{ opacity: 0 }}
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                ref={videoRef}
            />
        </Box>
    )
})

export default WebGLVideo;