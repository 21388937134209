import { Header, Button, Box, Text, Nav, Menu, ResponsiveContext, Layer, List, Heading } from 'grommet';
import { Close, Dashboard, Logout, SettingsOption, User } from 'grommet-icons';
import { TwitchAuth } from '../auth/TwitchAuth';
import { useGetCurrentUserQuery, useLogoutUserMutation } from '../../api/userApi';
import { WhatYouWishLogo } from '../../icons/WhatYouWishLogo';
import { useDispatch, useSelector } from 'react-redux';
import { authInProgressSelector, authenticationSelector, setAuthenticated, setIsAuthenticating } from '../../slices/authSlice';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AppHeader = () => {

  const responsive = useContext(ResponsiveContext);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const wss = useSelector(state => state.ws.clients);
  const connected = useSelector(state => state.ws.connected);
  const dispatch = useDispatch();
  const isAuthInProgress = useSelector(authInProgressSelector);
  const isAuthenticated = useSelector(authenticationSelector);
  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, {skip: !isAuthenticated});
  const [logoutUser] = useLogoutUserMutation();

  const myAccountItems = [
    {
      label: <Text weight={'bold'}>{t('dashboard')}</Text>,
      icon: <Dashboard />,
      onClick: () => navigate('/dashboard/events')
    },
    {
      label: <Text weight={'bold'}>{t('settings')}</Text>,
      icon: (<SettingsOption/>),
      onClick: () => navigate('/settings')
    },
    {
      label: <Text weight={'bold'}>{t('signOut')}</Text>,
      icon: (<Logout/>),
      onClick: () => handleSignOut()
    }
  ]

  const handleSignOut = () => {
    dispatch(setAuthenticated(false))
    logoutUser()
    Object.entries(connected).filter((clientName, value) => value)
    .forEach((clientName, value) => wss[clientName].deactivate())
  }

  useEffect(() => {
    if (isCurrentUserError) {
      dispatch(setIsAuthenticating(false))
      dispatch(setAuthenticated(false))
    }
  }, [isCurrentUserError])

  useEffect(() => {
    if (isCurrentUserSuccess) {
      dispatch(setIsAuthenticating(false))
    }
  }, [isCurrentUserSuccess])

  return (
    <Header
        fill="horizontal"
        pad={{ horizontal: 'medium', vertical: 'small' }}
        background="background-front">
      <Button>
        <Box
          direction="row"
          align="start"
          gap="medium"
          pad={{ vertical: 'none' }}
          responsive={false}
        >
          <Box direction="row" gap="xsmall" wrap>
              <WhatYouWishLogo/>
          </Box>
        </Box>
      </Button>
      <Box direction='row'>
        {!['xsmall', 'small', 'medium'].includes(responsive) && 
          <FullHeaderNavMenu 
            isAuthenticated={isAuthenticated} 
            isAuthInProgress={isAuthInProgress}
            myAccountItems={myAccountItems}
            isCurrentUserSuccess={isCurrentUserSuccess}
            currentUser={currentUser}
          />}
        {['xsmall', 'small', 'medium'].includes(responsive) && 
          <MobileHeaderNavMenu 
            isAuthenticated={isAuthenticated} 
            isAuthInProgress={isAuthInProgress}
            isCurrentUserSuccess={isCurrentUserSuccess}
            currentUser={currentUser}
            handleSignOut={handleSignOut}
          />}
      </Box>
    </Header>
  );
  };

const FullHeaderNavMenu = ({ isAuthenticated, isAuthInProgress, myAccountItems, isCurrentUserSuccess, currentUser }) => {

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Nav direction="row" background="background-front" pad="none" align='end'>
      <Button label={t('auctionHeaderButton')} onClick={() => navigate('/')}/>
      <Button label={t('rouletteHeaderButton')} onClick={() => navigate('/roulette')} />
      <Button label={t('memesHeaderButton')} onClick={() => navigate('/memes')} badge={<Box background={'brand'} round="xsmall" pad={{ top: '1px', bottom: '1px', left: '2px', right: '2px' }}><Text size='xsmall'>beta</Text></Box>} />
      {!isAuthenticated && !isAuthInProgress &&  (
        <TwitchAuth/>
      )}
      {isAuthInProgress && (
        <Button busy label="User"/>
      )}
      {isAuthenticated && (
        <Box direction='row' gap='small'>
          <Menu label={<Box gap='small' direction='row' align='center'>
            <User/>
            {isCurrentUserSuccess && (
              <Text weight={'bold'}>{currentUser.username}</Text>
            )}
          </Box>} items={myAccountItems} size='small'/>
        </Box>
      )}
      <LanguageSelector />
    </Nav>
  )
}

const MobileHeaderNavMenu = ({ isAuthenticated, isAuthInProgress, isCurrentUserSuccess, currentUser, handleSignOut }) => {

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState();

  const headerItems = [
    {
      label: t('auctionHeaderButton'),
      onClick: () => navigate('/')
    },
    {
      label: t('rouletteHeaderButton'),
      onClick: () => navigate('/roulette')
    },
    {
      label: t('memesHeaderButton'),
      onClick: () => navigate('/memes')
    }
  ]

  const myAccountItems = [
    {
      label: t('dashboard'),
      icon: <Dashboard />,
      onClick: () => navigate('/dashboard/events')
    },
    {
      label: t('settings'),
      icon: (<SettingsOption/>),
      onClick: () => navigate('/settings')
    },
    {
      label: t('signOut'),
      icon: (<Logout/>),
      onClick: () => handleSignOut()
    }
  ]

  return (
    <Nav direction="row" background="background-front" pad="none" align='end'>
      <Button label={t('menuBtnLabel')} onClick={() => setShowMenu(true)} />
      <LanguageSelector />
      {showMenu && <Layer position="right" full="vertical" onEsc={() => setShowMenu(false)} onClickOutside={() => setShowMenu(false)}>
        <Box>
          <Box direction='row' justify='between' align='center' pad={{ left: 'medium', right: 'medium' }}>
            <Heading level={2}>{t('mainMenuHeader')}</Heading>
            <Button icon={<Close/>} onClick={() => setShowMenu(false)} />
          </Box>
          <List
            primaryKey={( item ) => <Box direction='row' gap='small' align='center'><Text>{item.label}</Text></Box>}
            data={headerItems}
            onClickItem={({ item }) => item.onClick()}
          />
          {!isAuthenticated && !isAuthInProgress &&  (
            <Box pad={{ left: 'medium', right: 'medium', top: 'small', bottom: 'small' }}><TwitchAuth/></Box>
          )}
          {isAuthInProgress && (
            <Button busy label="User"/>
          )}
          {isAuthenticated && <Box>
            <Box direction='row' gap='small' align='center' pad={{ left: 'medium', right: 'medium', top: 'small', bottom: 'small' }}>
              <User/>
              {isCurrentUserSuccess && (
                <Text weight={'bold'}>{currentUser.username}</Text>
              )}
            </Box>
            <List
              primaryKey={( item ) => <Box direction='row' gap='small' align='center'>{item.icon}<Text>{item.label}</Text></Box>}
              data={myAccountItems}
              onClickItem={({ item }) => item.onClick()}
            />
          </Box>}
        </Box>
      </Layer>}
    </Nav>
  )
}