import { useDraggable } from "@dnd-kit/core";
import { Box, Image } from "grommet";
import {CSS} from '@dnd-kit/utilities';
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { VideoSizeContext } from "./SpotMemeWindow";
import VideoPlayer from "../../webgl/VideoPlayer";

export const DraggableMemePreview = ({ meme, styles, size, volume }) => {

    const handleVideoSize = useContext(VideoSizeContext);
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [videoNode, setVideoNode] = useState();
    const [videoSize, setVideoSize] = useState();
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
		id: `meme-preview`,
		data: {
            size: videoSize
        },
	});

    const style = {
        transform: CSS.Translate.toString(transform),
        // transform: `translate(${transform?.x}px, ${transform?.y}px)`,
        // opacity: isDragging ? 0.5 : null
    };

    const videoNodeRef = useCallback((node) => {
        if (!node) return;

        setVideoNode(node);
    }, [])
    
    useEffect(() => {
        if (meme?.chromaKey) {
            setChromaKeyConfig({ 
                chromaKey: meme.chromaKey,
                similarity: meme.similarity,
                smoothness: meme.smoothness,
                spill: meme.spill 
            });
        }
    }, [meme])

    useLayoutEffect(() => {
        if (videoNode) {
            const rect = videoNode.getBoundingClientRect();

            console.log(rect);
            
            handleVideoSize({ height: rect.height, width: rect.width });

            const resizeObserver = new ResizeObserver(() => {
                const rect = videoNode.getBoundingClientRect();
                console.log(rect);
                
                console.log('INIT');
                
                setVideoSize({ height: rect.height, width: rect.width });
            });
            resizeObserver.observe(videoNode);

            return () => resizeObserver.unobserve(videoNode);
        }
    }, [videoNode])

    useLayoutEffect(() => {
        handleVideoSize(videoSize);
    }, [videoSize])

    return (
        <Box 
            height={{ max: `${9999999}px` }} width={{ width: `${500 * size}px`, max: `${9999999}px` }}
            // border={{ color: '#FFFFFF', size: '2px', style: 'dotted' }}
            style={{  ...style, ...styles, ...{ cursor: 'move', outline: '2px dotted #FFFFFF' } }}
            ref={setNodeRef}
            {...attributes}
            {...listeners}>
                <Box /*style={{ position: 'relative' }}*/>
                    {/* <Image style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', visibility: isDragging ? 'visible' : 'hidden' }} src={meme.interactionPreview} /> */}
                    <Box ref={videoNodeRef} /*style={{ visibility: isDragging ? 'hidden' : 'visible' }}*/>
                        <VideoPlayer 
                            src={meme.interactionVideo} 
                            removeBgConfig={chromaKeyConfig}
                            autoPlay
                            loop
                            className="meme-preview"
                        />
                    </Box>
                </Box>
        </Box>
    )
}