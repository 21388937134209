import { forwardRef, useImperativeHandle } from "react"
import useWebGL from "./useWebGL"

const WebGLCanvas = forwardRef(({ removeBgConfig, ...props }, ref) => {

    const { canvasRef, play: playCanvas, stop: stopCanvas } = useWebGL(removeBgConfig);

    const play = (videoSource) => {
        playCanvas(videoSource)
    };

    const stop = () => {
        stopCanvas();
    };

    useImperativeHandle(ref, () => {
        return {
            play,
            stop
        }
    }, [])

    return (
        <canvas ref={canvasRef} {...props}>
            <p>Sorry your browser does not support HTML5</p>
        </canvas>
    )
})

export default WebGLCanvas;