export const vertexShaderSource = `
    attribute vec2 c;
    
    void main(void) {
        gl_Position=vec4(c, 0.0, 1.0);
    }
`

export const fragmentShaderSource = `
    precision highp float;

    uniform sampler2D tex;
    uniform float texWidth;
    uniform float texHeight;

    uniform vec3 keyColor;
    uniform float similarity;
    uniform float smoothness;
    uniform float spill;

    // From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
    vec2 RGBtoUV(vec3 rgb) {
        return vec2(
            rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
            rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
        );
    }

    vec4 ProcessChromaKey(vec2 texCoord) {
        vec4 rgba = texture2D(tex, texCoord);

        float chromaDist = distance(RGBtoUV(texture2D(tex, texCoord).rgb), RGBtoUV(keyColor));

        float baseMask = chromaDist - similarity;
        float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
        rgba.a = fullMask;

        float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
        float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
        rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

        return rgba;
    }

    void main(void) {
        vec2 texCoord = vec2(gl_FragCoord.x / texWidth, 1.0 - (gl_FragCoord.y / texHeight));
        gl_FragColor = ProcessChromaKey(texCoord);
    }
`