import { Box, ResponsiveContext } from "grommet"
import { createContext, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import '../grid/MemesGrid.css'
import { MemeDialog } from "../dialog/MemeDialog";
import { useDispatch, useSelector } from "react-redux";
import { setMemeModal } from "../../../slices/modalWindowsSlice"
import MemePreview from "./MemePreview";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useOnWindowResize, useOnWindowScroll } from "rooks";
import WebGLCanvas from "../../webgl/WebGLCanvas";

export const MemeHoverContext = createContext();

const colsCount = {
    xsmall: 1,
    small: 2,
    medium: 2,
    large: 4,
    xlarge: 5
}

export const MemesGrid = ({ memes, priceConfig, isDonatePage = false, hasNext, onLoad }) => {

    const responsive = useContext(ResponsiveContext);
    const dispatch = useDispatch();

    const { show, meme } = useSelector((state) => state.modalWindows.memeModal);

    const canvasRef = useRef();
    const [currentMeme, setCurrentMeme] = useState(null);
    const [position, setPosition] = useState({ transform: `translate(${0}px, ${0}px)` });
    const [size, setSize] = useState({ height: 0, width: 0 });
    const [containerOffset, setContainerOffset] = useState({ top: 0, left: 0 });
    const [containerNode, setContainerNode] = useState();

    const handleModalClose = () => {
        dispatch(setMemeModal({ show: false }));
    }

    const containerRef = useCallback((node) => {
        if (!node) return;

        const rect = node.getBoundingClientRect();
        setContainerOffset({ top: rect.top, left: rect.left });
        setContainerNode(node);
    }, [])

    const handleHoverEvent = (meme, videoNode, position, size) => {
        setPosition({ transform: `translate(${position.left - containerOffset.left}px, ${position.top - containerOffset.top}px)` });
        setSize(size);
        setCurrentMeme(meme);
        if (videoNode) {
            canvasRef.current.play(videoNode);
        } else {
            canvasRef.current.stop();
        }
    }

    useOnWindowResize(() => {
        const rect = containerNode.getBoundingClientRect();
        setContainerOffset({ top: rect.top - window.scrollY, left: rect.left - window.scrollX });
    }, [containerNode], true)

    useOnWindowScroll(() => {
        const rect = containerNode.getBoundingClientRect();
        setContainerOffset({ top: rect.top, left: rect.left });
    }, [containerNode], true)

    useEffect(() => {
        console.log(responsive);
        
    }, [responsive])

    return (
        <MemeHoverContext.Provider value={{ handleHoverEvent }}>
            <Box ref={containerRef}>
                <WebGLCanvas
                    ref={canvasRef} 
                    removeBgConfig={currentMeme?.chromaKey ? {
                        chromaKey: currentMeme.chromaKey,
                        similarity: currentMeme.similarity,
                        smoothness: currentMeme.smoothness,
                        spill: currentMeme.spill
                    } : null}
                    height={size.height}
                    width={size.width}
                    style={{ position: 'absolute', ...position, zIndex: 1, pointerEvents: 'none', height: `${size.height}px`, width: `${size.width}px` }}
                    className="meme-preview"
                />
                {memes?.length > 0 && <Box fill>
                    <InfiniteScroll
                        dataLength={memes.length}
                        next={onLoad}
                        hasMore={hasNext}
                        loader={<h4>Loading...</h4>}
                    >
                        <Masonry
                            breakpointCols={colsCount[responsive]}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {memes.map((meme) => <MemePreview key={meme.id} meme={meme} />)}
                        </Masonry>
                    </InfiniteScroll>
                </Box>}
                {show && <MemeDialog meme={meme} priceConfig={priceConfig} onClose={handleModalClose} isDonatePage={isDonatePage}/>}
            </Box>
        </MemeHoverContext.Provider>
    )
}

export default memo(MemesGrid);